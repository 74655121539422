import { useSelector } from 'react-redux'

import './App.css';
import './helexkids.css';

// import { columnInfos, columnNames } from './data/ColumnInfos';
import * as ColumnInfos from './data/ColumnInfos';

import { HXControls } from './ui/HXControls';
import { HXHelpDialog } from './ui/HXDialogs/HXHelpDialog';
import { HXHomePageContents } from './ui/HXHomePageContents';
import { HXLanguageSwitch } from './ui/HXLanguageSwitch';
import { HXLogo } from './ui/HXLogo';
import { HXMainMenu } from './ui/HXMainMenu';
import { HXTable } from './ui/HXTable/HXTable';
import { HXWordListDialog } from './ui/HXDialogs/HXWordListDialog';

function App() {
  const selectedLanguage = useSelector(state => state.selectedLanguage);
  const showHomePage = useSelector(state => state.showHomePage)
  const helpDialogIsOpen = useSelector(state => state.help.dialogIsOpen);
  const wordsListDialogIsOpen = useSelector(state => state.wordsList.dialogIsOpen);
  const dataFileName = useSelector(state => state.dataFileName)

  const showLemmas = dataFileName.toLowerCase().indexOf('lemma') >= 0;
  let columnInfos;
  let columnNames;
  console.log("ColumnInfos.lemmas", ColumnInfos.lemmas)
  if(showLemmas) {
    ({columnInfos, columnNames} = ColumnInfos.lemmas);
  } else {
    ({columnInfos, columnNames} = ColumnInfos.normal);
  }

  let className = '-mp-lay-fl ';
  className += (selectedLanguage === 'EL') ? 'EL-ACTIVE' : 'EN-ACTIVE';

  return (
    <div
      name="page_contents"
      className={className}
      style={{float:'none',margin:'auto',minHeight:'800px',position:'relative',width:'1100px'}}
    >
      <div
        name="include" className="font" style={{margin:'0px',width:'100%',}}>
        <div
          name="top-group"
          id="~page_contents~include~top-group"
          className="-mp-lay-fl"
          style={{margin:'0px',width:'100%',}}
        >
          <HXLogo />
          <h1 name="heading" style={{height:'44px',margin:'0px 0px 0px 2px',}}>
            HelexKids Word Frequencies<br/>
          </h1>
          <HXMainMenu />
        </div>
      </div>

      {
        showHomePage &&
        <h1
          name="heading"
          style={{backgroundColor:'#e6e6e6',left:'146px',position:'absolute',top:'-21px',width:'461px',}}>
            Welcome to HelexKids<br/>
        </h1>
      }

      {
        showHomePage &&
        <HXLanguageSwitch />
      }
      {
        showHomePage &&
        <HXHomePageContents />
      }

      {
        !showHomePage &&
        <>
          <HXControls />

          <HXTable
            columnInfos={columnInfos}
            columnNames={columnNames}
          />
        </>
        }

      {
        helpDialogIsOpen &&
        <HXHelpDialog />
      }
      {
        wordsListDialogIsOpen &&
        <HXWordListDialog />
      }

    </div>
  );
}

export default App;
