import PropTypes from 'prop-types';
import { useState, useRef } from "react";
import * as Browser from '../util/Browser';
import { Pane } from '../util/Pane';
import { Loc } from '../util/Plane';

export const HXPopMenu = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const thisElem = useRef(null);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleHeadClick = event => {
    setIsOpen(true);
    const thisPane = new Pane(thisElem.current);
    positionBody(thisPane, props.selectedOption || props.availableOptions[2].name);
  }
  const handleNotBodyClick = event => {
    setIsOpen(false);
  }
  const handleBodyClick = event => {
    const eventPane = new Pane(event.target);
    const itemPane = eventPane.findOutPane('.-hx-popmenu-item');
    const itemKey = itemPane.getAttr('name');
    props.onChange(itemKey);
    setIsOpen(false);
  }


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // rendering
  let selectedPopOption = props.availableOptions.filter((o) => {
    return o.name === props.selectedOption;
  });
  selectedPopOption = selectedPopOption.length ? selectedPopOption[0] : null;

  let contentsRender = [ selectedPopOption ].map((o) => {
    if (!o) {
      return (
        <div key="-" className="-hx-popmenu-item -mp-lay-fl">
          <div name="display" className="-hx-popmenu-item-text">
            {(selectedPopOption && selectedPopOption.display) || '???'}
          </div>
        </div>
      )
    }
    return (
      <div key={o.name} name={ o.name } className="-hx-popmenu-item -mp-lay-fl">
        <div name="display" className="-hx-popmenu-item-text">
          { o.display }
        </div>
      </div>
      )
  });

  let bodyRender = props.availableOptions.map((o) => {
    return (
      <div key={o.name} name={ o.name } className="-hx-popmenu-item -mp-lay-fl">
        <div name="display" className="-hx-popmenu-item-text">
          { o.display }
        </div>
      </div>
    )
  });

  return (
    <div ref={thisElem} className={'-hx-popmenu condition-pop -mp-lay-fl ' + (isOpen && 'OPEN')}>
      <div name="head" className="-hx-popmenu-head -mp-lay-fl" onClick={handleHeadClick}>
        <div name="contents" className="-hx-popmenu-contents -mp-lay-fl">
          { contentsRender }
        </div>
        <div name="arr_group" className="-hx-popmenu-arrow-enc -mp-lay-fl">
          <div name="arr" className="-hx-popmenu-arrow -mp-lay-fl"></div>
        </div>
      </div>
      <div name="not-body" className="-hx-popmenu-not-body" onClick={handleNotBodyClick}>
      </div>
      <div name="body" className="-hx-popmenu-body -mp-lay-fl" onClick={handleBodyClick}>
        { bodyRender }
      </div>
    </div>
  );
};

HXPopMenu.propTypes = {
  isOpen: PropTypes.bool,
  availableOptions: PropTypes.arrayOf(PropTypes.shape({name: PropTypes.string, display: PropTypes.string})),
  selectedOption: PropTypes.string,
  onChange: PropTypes.func,
}

const positionBody = (popPane, selectedOption) => {
  var body = popPane.$( "body" );
  var sel_item = selectedOption && body.$( selectedOption, null );
  if( !sel_item ) {
      console.warn( "no sel item for " + selectedOption );
      // return;
  }

  // we need the pane to display so we can calculate sel_item position;
  // this adds 'display' to body's local style which we have to remove later
  body.setDisplay( true );
  var body_pos = new Loc();

  body.getChildPanes().forEach( function( ip ) {
      ip.rmvStyleName( "SELECTED" );
  } );
  var top_dist = 1;
  if( sel_item ) {
      sel_item.addStyleName( "SELECTED" );
      top_dist = sel_item.getLocRel( body ).getVert();
  }
  // got to remove 'display' prop from local style
  Browser.elem.style.writeProp(body.elem().style, 'display', null);

  body.setLocRel( popPane, body_pos.offset( 0, top_dist ) );

      // ensure body is at least as wide as us
  var this_wid = popPane.getBounds().getWidth();
  var body_bnds = body.getBounds();
  var body_wid = body_bnds.getWidth();
  if( body_wid < this_wid ) {
      body_bnds.setWidth( this_wid );
      body.setBounds( body_bnds );
  }
}


