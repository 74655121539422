import React from "react";

import { useDispatch } from 'react-redux'

export const HXLanguageSwitch = (props) => {

  const dispatch = useDispatch();
  const handleClick = (selectedLanguage) => {
    dispatch({type: 'selectedLanguage', payload: selectedLanguage});
  }

  return (
    <div 
      name="lang_switch" 
      id="~page_contents~lang_switch" 
      className="-mp-lay-fl" 
      style={{cursor:'pointer',position:'absolute',right:'8px',top:'26px',}}>
      <div 
        onClick={() => handleClick('EL')}
        name="EL" 
        className="flag" 
        style={{backgroundPosition:'center bottom',borderRadius:'4px 0px 0px 4px',}}>
      </div>
      <div 
        onClick={() => handleClick('EN')}
        name="EN" 
        className="flag" 
        style={{borderRadius:'0px 4px 4px 0px',}}>        
      </div>
    </div>
  )
}
