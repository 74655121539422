import React from "react";

export const HXHomePageContents = (props) => {

  return (
    <>
      <div name="left-col" style={{boxSizing:'border-box',fontSize:'14px',lineHeight:'1.6',padding:'20px 100px 20px 50px',width:'50%'}}>
        <p name="p" className="lang-en" style={{lineHeight:'1.6',marginBottom:'0px',}}>This is the website of <span style={{color:'#303cff'}}>HelexKids</span>, a word frequency database that was developed for Greek/Cypriot children from the first to the sixth grade of primary education. The database can be used for psycholinguistic experimental studies as well as for educational practitioners for educational purposes.</p>
        <p name="p-2" className="lang-el" style={{lineHeight:'22px;margin',bottom:'0px',}}>Αυτή είναι η ιστοσελίδα της ηλεκτρονικής βάσης HelexKids, η οποία περιλαμβάνει λέξεις και τις συχνότητες εμφάνισής τους. Δημιουργήθηκε για Έλληνες και Κύπριους μαθητές του Δημοτικού, από την πρώτη ως και την έκτη τάξη. Η ηλεκτρονική βάση μπορεί να χρησιμοποιηθεί για πειραματικές έρευνες στο χώρο της ψυχογλωσσολογίας καθώς και από εκπαιδευτικούς στην εκπαιδευτική πράξη.</p>
        <p name="p_022" className="lang-en" style={{color:'#000000',lineHeight:'1.6',marginBottom:'0px',}}><span style={{color:'#114cff'}}>HelexΚids&nbsp;</span>is based on a corpus of 1.3 million words extracted from 116 textbooks covering a wide range of readers from a variety of topics : mathematics, science, art, history, geography, literature, religion, theatre and physical education. The database consists of 12 lexicons, one for each grade (grade 1 to grade 6) and five cumulative (grade 1 and 2, grades 1 to 3, grades 1 to 4. grades 1 to 5 and grades 1 to 6 (all grades).Finally, the lemma lexicon is linked to the grade 1 to&nbsp; 6 lexicon.</p>
        <p name="p_024" className="lang-el" style={{lineHeight:'22px',marginBottom:'0px',}}>Η ηλεκτρονική βάση HelexΚids βασίζεται σε 1,3 εκατομμύρια λέξεις που προέρχονται από 116 βιβλία που χρησιμοποιούνται στο Δημοτικό σχολείο. Τα βιβλία έχουν μεγάλο θεματικό εύρος: μαθηματικά, φυσικές επιστήμες, τέχνες, ιστορία, γεωγραφία, λογοτεχνία, θρησκευτικά, θεατρική αγωγή και φυσική αγωγή. Η ηλεκτρονική βάση έχει 12 λεξικά, ένα για κάθε μία από τις έξι τάξεις, ένα για όλες τις τάξεις και 4 για τις τάξεις Πρώτη και Δευτέρα, Πρώτη ως και Τρίτη, Πρώτη ως και Τετάρτη και Πρώτη ως και Πέμπτη. Τέλος, το λεξικό λημμάτων είναι συνδεδεμένο με το λεξικό Πρώτη ως Έκτη.</p>
        <p name="p_023" className="lang-en" style={{lineHeight:'1.6',marginBottom:'0px',}}>The users can <span style={{fontSize:'16px',color:'#040404',}}>apply</span> search criteria and choose filter variables, paste lists of words and extract results to csv files or do word by word search.&nbsp;They can also download the lexicons as excel files.&nbsp;</p>
        <p name="p_025" className="lang-el" style={{lineHeight:'25px',marginBottom:'0px',}}>Οι χρήστες μπορούν να χρησιμοποιήσουν κριτήρια αναζήτησης και να διαλέξουν κατάλληλα φίλτρα, να επικολλήσουν λίστες λέξεων για τις οποίες τα αποτελέσματα μπορούν να εξαχθούν σε csv αρχεία ή να κάνουν αναζήτηση κάθε λέξης ξεχωριστά. Επίσης μπορούν να κατεβάσουν τα αρχεία της βάσης σε μορφή Excel.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p name="p_034" className="lang-en" style={{fontStyle:'italic',lineHeight:'1.6',marginBottom:'0px',}}>For further details you can download the paper that describes the database: Terzopoulos, Duncan, Wilson, Niolaki, Masterson (2016) HelexKids: a Greek word frequency database for children 6 to 12 years old. Behavior Research Methods, 1-14. doi: 10.3758/s13428-015-0698-5. Available at&nbsp; http://link.springer.com/article/10.3758/s13428-015-0698-5</p>
        <p name="p_042" className="lang-el" style={{lineHeight:'1.6',marginBottom:'0px',}}>Για περισσότερες πληροφορίες μπορείτε να κατεβάσετε το άρθρο που περιγράφει την ηλεκτρονική βάση: Terzopoulos, Duncan, Wilson, Niolaki, Masterson (under review). HelexKids: A word frequency database for Greek and Cypriot primary school children. <span style={{fontStyle:'italic',}}>Behavior Research methods.</span>
        </p>
        <p name="p_036" style={{lineHeight:'1.6',marginBottom:'0px'}}><span name="?ledy" style={{fontWeight:'bold'}}>Aris Terzopoulos</span>: <a name="a" href="https://www.coventry.ac.uk/research/researchers/aris-terzopoulos/" target="_blank" rel="noreferrer">https://www.coventry.ac.uk/research/researchers/aris-terzopoulos/</a></p>
        <p name="p_037" style={{lineHeight:'1.6',marginBottom:'0px',}}><span name="?ardu" style={{fontWeight:'bold'}}>Dr. Lynne Duncan</span>: <a name="a" href="https://www.dundee.ac.uk/people/lynne-duncan" target="_blank" rel="noreferrer">https://www.dundee.ac.uk/people/lynne-duncan</a></p>
        <p name="?fmba"></p>
        <a name="a-2" style={{margin:'10px 0px 0px 0px'}} href="http://www.languageandlearninglab.org/" target="_blank" rel="noreferrer">www.languageandlearninglab.org</a>
        <p></p>
        <p name="p_039" style={{lineHeight:'1.6',marginBottom:'0px',}}><span name="?kqmp" style={{fontWeight:'bold',}}>Dr. Georgia Niolaki</span>: <a name="a" href="https://www.coventry.ac.uk/research/researchers/dr-georgia-z-niolaki/" target="_blank" rel="noreferrer">https://www.coventry.ac.uk/research/researchers/dr-georgia-z-niolaki/</a></p>
        <p name="p_040" style={{lineHeight:'1.6',marginBottom:'0px'}}><span name="?pqco" style={{fontWeight:'bold'}}>Prof. Jackie Masterson</span>: <a name="a" href="https://iris.ucl.ac.uk/iris/browse/profile?upi=JMAST91" target="_blank" rel="noreferrer">https://iris.ucl.ac.uk/iris/browse/profile?upi=JMAST91</a></p>
        <p name="p_038" style={{lineHeight:'1.6',marginBottom:'0px'}}><span name="?bwtq" style={{fontWeight:'bold'}}>Mark Wilson</span>:&nbsp; <a name="a" href="http://www.manypage.com">www.manypage.com</a></p>
        <p name="p_044" className="lang-el" style={{lineHeight:'1.6',marginBottom:'0px',}}><a name="a-1" href="/books-list"> ΚΑΤΑΛΟΓΟΣ ΒΙΒΛΙΩΝ ΠΟΥ ΧΡΗΣΙΜΟΠΟΙΗΘΗΚΑΝ</a></p>
        <p name="p_043" className="lang-en" style={{lineHeight:'1.6',marginBottom:'0px',}}><a name="a-1" href="/books-list"> HELEXKIDS BOOKS LIST</a></p>
        <p name="p_041" style={{lineHeight:'1.6',marginBottom:'0px',}}></p>
      </div>
      <div name="right-col" style={{boxSizing:'border-box',fontSize:'14px',lineHeight:'1.6',padding:'20px 50px 20px 100px',width:'50%',}}>
        <p name="p_024" className="lang-en" style={{marginBottom:'0px',}}>There are 10 columns in the total lexicon that correspond to 10 variables: Frequency Counts (F), Zipf, Frequency per million(F/m), Log Frequency per million(LogF/m), &nbsp;Dispersion (D), Estimated frequency per million (U), Standard Frequency Index (SFI),<span style={{lineHeight:'22px'}}> Contextual Diversity (CD), orthographic Levenshtein distance (OLD20), N</span><span style={{lineHeight:'1.6',}}>umber of Letters (NLetters). The other lexicons have 8 columns as Zipf and OLD20 values were not included. The lemma lexicon has at the moment 3 columns (F, F/m and LogF/m).</span>
        </p>
        <p name="p_026" className="lang-el" style={{fontSize:'14px',lineHeight:'22px;margin',bottom:'0px',}}>Στο συγκεντρωτικό λεξικό όλων των τάξεων υπάρχουν 10 στήλες που αντιστοιχούν σε 10 μεταβλητές: Συχνότητα Εμφάνισης (F), Zipf, Συχνότητα εμφάνισης ανά εκατομμύριο (F/m), Λογάριθμος συχνότητας εμφάνισης ανά εκατομμύριο (LogF/m), Διασπορά (D), Εκτιμώμενη συχνότητα ανά εκατομμύριο (U), &nbsp;Σταθμισμένος δείκτης συχνότητας (SFI), Κειμενική ποικιλία (CD), ορθογραφική απόσταση Λεβενστάιν (OLD20) και Αριθμός γραμμάτων (NLetters). Τα υπόλοιπα λεξικά έχουν 8 στήλες καθώς οι τιμές Zipf και OLD20 δεν συμπεριελήφθησαν.Στο λεξικό λημμάτων πορς το παρόν υπάρχουν μόνο οι μεταβλητές F, F/m και LogF/m</p>
        <p name="p"></p>
        <p name="p_011" className="lang-en" style={{fontSize:'14px',lineHeight:'1.6',}}><span className="lited">Frequency Counts (F)</span> : raw number of word occurrences in the textbooks.</p>
        <p name="p_012" className="lang-el" style={{fontSize:'14px',height:'56px',lineHeight:'1.6',}}><span className="lited">Συχνότητα Εμφάνισης (F)</span> : Ο αριθμός εμφανίσεων των λέξεων στα βιβλία. &nbsp;</p>
        <p name="p_025" className="lang-en" style={{display:'none',marginBottom:'0px',}}><span className="lited">Zipf</span> : This is a standardised frequency value expressed in a logarithmic scale first introduced by vanHeuven et al (2014). The scale ranges from 1 (very low frequency) to 7 (very high frequency).</p>
        <p name="p_028" className="lang-el" style={{display:'none',marginBottom:'0px',}}><span className="lited">Zipf</span> : Είναι μια σταθμισμένη τιμή συχνότητας εκφρασμένη σε λογαριθμική σκάλα που εισήχθη πρώτη φορά από τους vanHeuven et al (2014). Οι αριθμητικές τιμές εκτείνονται από το 1 (πολύ χαμηλή συχνότητα) ως και το 7 (πολύ υψηλή συχνότητα).</p>
        <p name="p_027" className="lang-en" style={{marginBottom:'0px',}}><span className="lited">Frequency per million (F/m)</span> : is calculated as Frequency Counts * 1,000,000/Number of tokens.</p>
        <p name="p_034" className="lang-el" style={{marginBottom:'0px',}}><span className="lited">Συχνότητα ανά εκατομμύριο (F/m)</span> : προκύπτει από τον μαθηματικό τύπο Συχνότητα εμφάνισης * 1.000.000 / Αριθμός λέξεων.</p>
        <p name="p_029" className="lang-en" style={{marginBottom:'0px',}}><span className="lited">LOG Frequency per million (logF/m)</span> : It is the log10(Frequency per million) + 1.</p>
        <p name="p_035" className="lang-el" style={{fontSize:'14px',lineHeight:'1.6',marginBottom:'0px',textAlign:'left',}}><span className="lited">Λογάριθμος συχνότητας εμφάνισης ανά εκατομμύριο (logF/m)</span> : Προκύπτει από τον μαθηματικό υπολογισμό log10(Συχνότητα ανά εκατομμύριο) + 1.</p>
        <p name="p_030" className="lang-en" style={{marginBottom:'0px',textAlign:'left',}}><span className="lited">Dispersion (D)</span> : Dispersion of words across textbooks for each grade and for all grades. When D=1 then the word appears in the same proportion in all textbooks and when D=0 then it appears in one only textbook.</p>
        <p name="p_036" className="lang-el" style={{fontSize:'14px',lineHeight:'1.6',marginBottom:'0px',textAlign:'justify',}}><span className="lited">Διασπορά (D)</span> : Διασπορά των λέξεων στα βιβλία κάθε τάξης και σε όλες τις τάξεις. Αν D=1 τότε η λέξη εμφανίζεται ακριβώς στη ίδια αναλογία σε όλα τα βιβλία. Αν D=0 τότε εμφανίζεται μόνο σε ένα βιβλίο.</p>
        <p name="p_031" className="lang-en" style={{marginBottom:'0px',}}><span className="lited">Estimated frequency per million (U)</span> : This index derives from the frequency per million index but is adjusted to D. If D=1 then U is the frequency per million and when D&lt;1 then U is adjusted downwards. If D=0 then U has a minimum value based on the average weighted probability of that word in the 116 textbooks.</p>
        <p name="p_037" className="lang-el" style={{marginBottom:'0px',}}><span className="lited">Εκτιμώμενη συχνότητα ανά εκατομμύριο (U)</span> : Ο δείκτης προέρχεται από τη Συχνότητα εμφάνισης ανά εκατομμύριο αλλά είναι προσαρμοσμένος στην τιμή Διασποράς. Αν D=1 τότε ο δείκτης U ισοδυναμεί με τη συχνότητα εμφάνισης ανά εκατομμύριο. Αν D&lt;1 τότε ο δείκτης U προσαρμόζεται προς χαμηλότερες τιμές. Αν D=0 τότε ο δείκτης U έχει ελάχιστη τιμή που βασίζεται στη μέση σταθμισμένη τιμή της πιθανότητας εμφάνισης της λέξης στα 116 βιβλία.</p>
        <p name="p_032" className="lang-en" style={{marginBottom:'0px',}}><span className="lited">Standard Frequency Index (SFI)</span> : this is an index derived from U. SFI of 90 means that a word occurs once every 10 tokens, of 80 once every 100 tokens, of 70 every 1,000 tokens and so on.</p>
        <p name="p_038" className="lang-el" style={{marginBottom:'0px',}}><span className="lited">Σταθμισμένος δείκτης συχνότητας (SFI)</span> : Ο δείκτης προέρχεται από το δείκτη U. Όταν ο δείκτης SFI ισούται με 90 σημαίνει ότι η λέξη εμφανίζεται μία φορά κάθε 10 λέξεις. Όταν ισούται με 80 μία φορά ανά 100 λέξεις, 70 μία φορά ανά 1.000 λέξεις κ.ο.κ.</p>
        <p name="p_038-23" className="lang-el"><span className="lited">Ορθογραφική απόσταση Λεβενστάιν (OLD20)</span>: Η απόσταση Λεβενστάιν ορίζεται ως ο ελάχιστος αριθμός αντικαταστάσεων, διαγραφών και προσθέσεων γραμμάτων για τη μετατροπή μιας λέξης σε άλλη. Η τιμή OLD20 υπολογίζεται ως ο μέσος όρος αυτών των αλλαγών μεταξύ μιας λέξης και των 20 πλησιέστερων ορθογραφικών γειτόνων της.&nbsp;</p>
        <p name="p_033" className="lang-en" style={{marginBottom:'0px',}}><span className="lited">Contextual Diversity (CD)</span> : This is an index of the occurrences a word has in different textbooks for each grade and for all grades. It is simply calculated as the number of textbooks a word appears in a grade divided by the total number of textbooks for that grade. It equals 1 if the word appears in all textbooks and takes values from 0.1 to 1.</p>
        <p name="p_040" className="lang-en" style={{marginBottom:'0px',}}><span className="lited">Orthographic Levenshtein Distance (OLD20)</span> : This is the average number of substitutions, deletions and additions between a word and its 20 closest orthographic neighbours that are necessary to turn one word into another.&nbsp;&nbsp;</p>
        <p name="p_039" className="lang-el" style={{marginBottom:'0px',}}><span className="lited">Κειμενική ποικιλία (CD)</span> : Είναι ένας δείκτης της συχνότητας εμφάνισης μιας λέξης σε διαφορετικά βιβλία σε κάθε τάξη και σε όλες τις τάξεις. Υπολογίζεται απλά ως το πηλίκο του αριθμού των βιβλίων που εμφανίζεται μια λέξη προς το συνολικό αριθμό των βιβλίων της τάξης. Ισούται με 1 αν η λέξη εμφανίζεται σε όλα τα βιβλία και παίρνει τιμές από 0.1 ως 1.</p>
        <p name="p_041" style={{marginBottom:'0px',}}></p>
      </div>
    </>
  );
};

HXHomePageContents.propTypes = {
}