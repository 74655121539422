export const BASE_DATA_URL = '/database-files/';

export const TEXTUAL_CONDITIONS = [
    { name: "equals", display: "Equals" },
    { name: "contains", display: "Contains" },
    { name: "omits", display: "Omits" },
    { name: "starts", display: "Starts" },
    { name: "ends", display: "Ends" },
]

export const NUMERICAL_CONDITIONS = [
    { name: 'eq', display: '=' },
    { name: 'ne', display: '!=' },
    { name: 'lt', display: '<' },
    { name: 'le', display: '<=' },
    { name: 'gt', display: '>' },
    { name: 'ge', display: '>=' },
];

