import React from "react";
import school from '../school.jpg';

export const HXLogo = (props) => {
  return (
    <div 
      name="logo" 
      className="-mp-lay-fl" 
      style={{borderColor:'#717171',borderRadius:'50%',borderStyle:'solid',borderWidth:'1px',height:'122px',margin:'10px',overflowX:'hidden',overflowY:'hidden',width:'122px',}}
    >
      <img name="image" 
        style={{height:'247px',margin:'-9px 0px 0px -220px',width:'507px',}} 
        src={school}
        alt="ancient-greek-school"
      />

    </div>
  )
}