import React from "react";
import PropTypes from 'prop-types';

export const HXTableRow = React.forwardRef((props, ref) => {
  const handleClick = (event) => {
    // console.log('HXTableRow.handleClick', event);
  }

  const styleNames = [];
  if(props.rowIndex === 0) {
    styleNames.push('REPEAT-FIRST');
  }
  if(props.rowIndex % 2) {
    styleNames.push('REPEAT-EVEN');
  } else {
    styleNames.push('REPEAT-ODD');
  }
  return (
    (
      <tr
        ref={ref}
        key={props.rowData.word}
        name={props.rowData.word}
        className={styleNames.join(' ')}
        onClick={handleClick}
      >

        {
          props.columnNames.map( (columnName, colIdx) => {
            return <HXTableRowCell
              name={columnName}
              value={props.rowData[props.columnInfos[columnName].name] + ''}
              index={colIdx}
              key={columnName}
            />
          })
        }
      </tr>
    )
  )
});

HXTableRow.propTypes = {
  columnInfos: PropTypes.object,
  columnNames: PropTypes.arrayOf(PropTypes.string),
  rowIndex: PropTypes.number,
  rowData: PropTypes.object,
}


export const HXTableRowCell = (props) => {
  return (
    <td name={ props.name } className={ props.name + (props.index % 2 ? ' col_odd' : '') }>
      { props.value }
    </td>
  )
}
HXTableRowCell.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  index: PropTypes.number,
};

