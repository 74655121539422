import React, {useState, useRef, useEffect} from "react";
import PropTypes from 'prop-types';

import { Bounds } from '../../util/Plane';
import * as Browser from '../../util/Browser';

const DEFAULT_WIDTH = 332;

export const HXWindow = (props) => {
  const [adjustedStyle, setAdjustedStyle] = useState({});

  const thisElem = useRef(null);

  let localStyle = {
    visibility: 'hidden',
    ...adjustedStyle,
  }

  useEffect( () => {
    const width = props.width || DEFAULT_WIDTH;
    const height = thisElem.current.clientHeight;
    const winBounds = Browser.wind_port.getWinBounds();
    winBounds.setBot(winBounds.getBot() * 0.66);
    let thisBounds = new Bounds(width, height);
    thisBounds = thisBounds.centerIn(winBounds);
    const newStyle = {
      ...localStyle,
      visibility: 'visible',
      width: width + 'px',
      left: thisBounds.getLeft() + 'px',
      top: thisBounds.getTop() + 'px',
    };
    const styleChanges = Object.keys(newStyle).filter( k => {
      return adjustedStyle[k] !== newStyle[k];
    });
    if(styleChanges.length) {
      setAdjustedStyle(newStyle);
    }
  }, [adjustedStyle, localStyle, props.width]);
  

  return (
    <>
      {/* <div name="dialog-blocker" className="-mp-window-blocker"></div> */}
      <div ref={thisElem} style={localStyle} className="-mp-dialog -mp-window -mp-lay-fl" >
        <table name="whead" className="-mp-window-whead" cellPadding="0" cellSpacing="0">
            <tbody name="!klgw">
              <tr name="wrow" className="-mp-window-wrow">
                  <td name="wlcell" className="-mp-window-wlcell">
                    <div name="wfold" className="-mp-wfold -mp-wfold--alert"></div>
                  </td>
                  <td name="wrcell" className="-mp-window-wrcell -mp-window-wrcell--alert">
                    <div name="title_bar" className="-mp-window-title-bar -mp-window-title-bar--alert"></div>
                  </td>
              </tr>
            </tbody>
        </table>
        <div className="-mp-window-body -mp-window-body--alert" name="!resv">
            <div name="head" className="text_base -mp-dialog-head -mp-window-head -mp-lay-fl">
              <div name="message" className="-mp-dialog-head-message  -mp-window-head-message">
                  {props.title}
              </div>
            </div>
            <div name="contents" className="-mp-dialog-contents -mp-lay-fl">
              {props.children}
            </div>
        </div>
        <div name="wfold_fg" className="-mp-wfold-fg"></div>
      </div>
    </>
  )
}

HXWindow.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
}