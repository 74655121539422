export const normal = {
  columnInfos: {
    words: { name: "words", title: "Word", },
    zipf: { name: "zipf", title: 'zipf', },
    frequency_counts: { name: "frequency_counts", title: 'Freq Counts', },
    frequency_per_million: { name: "frequency_per_million", title: 'Freq / M', },
    log_frequency_per_million: { name: "log_frequency_per_million", title: 'Log(Freq / M)', },
    dispersion: { name: "dispersion", title: 'Dispersion', },
    u: { name: "u", title: 'U', },
    sfi: { name: "sfi", title: 'SFI', },
    contextual_diversity: { name: "contextual_diversity", title: 'CD', },
    n_letters: { name: "n_letters", title: 'N Letters', },
  },

  // this specifies the order of the columns
  columnNames: [
    "words",
    "frequency_counts",
    // "zipf",
    "frequency_per_million",
    "log_frequency_per_million",
    "dispersion",
    "u",
    "sfi",
    "contextual_diversity",
    "n_letters" ,
  ]
};

export const lemmas = {
  columnInfos: {
    lemma: { name: "LEMMA", title: "Lemma", },
    frequency: { name: "FREQUENCY", title: "Frequency", },
    f_m: { name: "f/M", title: "Freq / M", },
    log_f_m: { name: "LOG F/M", title: "Log(Freq / M)", },
  },
  columnNames: [
  'lemma',
  'frequency',
  'f_m',
  'log_f_m',
  ],
}