import React from "react";
import { useDispatch } from 'react-redux'

import * as constants from '../constants';

import { HXDownloadCsv } from './HXDownloadCsv';
import { HXDownloadExcel } from './HXDownloadExcel';
import { HXFilterBox } from './HXFilterBox';
import { HXWordsListControls } from './HXWordsListControls';

export const HXControls = (props) => {

  const dispatch = useDispatch();
  const handleOpenHelpDialog = () => {
    dispatch({type: 'help/dialogIsOpen', payload: true});
  }

  return (

    <div name="controls" id="~page~controls" className="-mp-lay-fl" 
      style={{borderColor:'#888888',borderStyle:'solid',borderWidth:'0px',clear:'both',margin:'0px',width:'260px',}}>
        <div name="label_012" className="controls-label" 
        style={{width:'72%',}}>
        FILTERS
      </div>
      <div 
        name="help_btn" 
        className="-mp-lay-fl" 
        onClick={handleOpenHelpDialog}
        style={{borderColor:'#888888',borderRadius:'50%',borderStyle:'solid',borderWidth:'0px',cursor:'pointer',float:'right',height:'30px',position:'relative',width:'30px',}} >
        <div name="label" 
          style={{color:'#666666',fontSize:'26px',fontWeight:'bold',lineHeight:'30px',textAlign:'center',width:'100%',}}>
          ?
        </div>
      </div>
        
      <HXFilterBox 
        filterName="words"
        title="Word"
        availableConditions={constants.TEXTUAL_CONDITIONS}
      />
      <HXFilterBox 
        filterName="frequency_counts"
        title="Freq Counts (Frequency Counts)"
        availableConditions={constants.NUMERICAL_CONDITIONS}
      />
      {/* <HXFilterBox 
        filterName="zipf"
        title="Zipf"
        availableConditions={constants.NUMERICAL_CONDITIONS}
      /> */}
      <HXFilterBox 
        filterName="frequency_per_million"
        title="Freq / M (Frequency per Million)"
        availableConditions={constants.NUMERICAL_CONDITIONS}
      />
      <HXFilterBox 
        filterName="log_frequency_per_million"
        title="Log(Freq / M) (Log Frequency per Million)"
        availableConditions={constants.NUMERICAL_CONDITIONS}
      />
      <HXFilterBox 
        filterName="contextual_diversity"
        title="CD (Contextual Diversity)"
        availableConditions={constants.NUMERICAL_CONDITIONS}
      />
      <HXFilterBox 
        filterName="dispersion"
        title="Dispersion"
        availableConditions={constants.NUMERICAL_CONDITIONS}
      />
      <HXFilterBox 
        filterName="u"
        title="U (Estimated Frequency per Million)"
        availableConditions={constants.NUMERICAL_CONDITIONS}
      />
      <HXFilterBox 
        filterName="sfi"
        title="SFI (Standard Frequency Index)"
        availableConditions={constants.NUMERICAL_CONDITIONS}
      />
      <HXFilterBox 
        filterName="n_letters"
        title="N Letters (Number of Letters)"
        availableConditions={constants.NUMERICAL_CONDITIONS}
      />

      <div name="label_013" className="controls-label" 
        style={{margin:'2px 0px 0px 0px',}}>
        SELECT
      </div>

      <HXWordsListControls />

      <HXDownloadCsv />
      
      <HXDownloadExcel />          

    </div>
  )

}

HXControls.propTypes = {
}