import React, { useRef } from "react";
import { useSelector } from 'react-redux'

import { calculateExcelDataUrl } from '../data/calculateDataUrl';

import { HXButton } from './HXButton';


export const HXDownloadExcel = (props) => {

  const downloadElem = useRef(null);
  const dataFileName = useSelector(state => state.dataFileName)
  const downloadUrl = calculateExcelDataUrl(dataFileName);

  const doDownload = async () => {
    downloadElem.current.click();    
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (
    <>
      <HXButton 
        title="download all as excel"
        typeClass="-hx-button-success"
        width={200}
        extraStyle={{margin:'16px 0px 10px 28px'}}
        onClick={doDownload}
      />
      
      <a
        ref={downloadElem}
        href={downloadUrl}
        download={`HelexWordFrequencies.xlsx`}
        style={{display:'none'}}
      >
        download
      </a>
    </>
  ) 
}

HXDownloadExcel.propTypes = {
}
  