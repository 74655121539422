import React from "react";
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux'

export const HXMainMenu = (props) => {

  const showHomePage = useSelector(state => state.showHomePage);
  let currDataFileName = useSelector(state => state.dataFileName);
  currDataFileName = showHomePage ? '' : currDataFileName;

  const showLemmas = ['Cumulative-Years-1-to-6', 'Lemmas'].includes(currDataFileName);

  return (
    <div name="main_menu" className="-mp-lay-fl"
      style={{lineHeight:2, margin:'15px 0px 0px 0px',position:'relative',width:'954px'}}>
      <div name="top-row" className="-mp-lay-fl"
        style={{width:'100%'}}
      >
        <HXMainMenuButton
          title={'Grade 1'}
          dataFileName={'The-Database-Year-1-corrected'}
          currDataFileName={currDataFileName}
          extraStyle={{borderRadius:'40px 0px 0px 0px', borderWidth: '1px 0px 1px 1px'}}
        />
        <HXMainMenuButton
          title={'Grade 2'}
          dataFileName={'The-Database-Year-2-corrected'}
          currDataFileName={currDataFileName}
        />
        <HXMainMenuButton
          title={'Grade 3'}
          dataFileName={'The-Database-Year-3-corrected'}
          currDataFileName={currDataFileName}
        />
        <HXMainMenuButton
          title={'Grade 4'}
          dataFileName={'The-Database-Year-4-corrected'}
          currDataFileName={currDataFileName}
        />
        <HXMainMenuButton
          title={'Grade 5'}
          dataFileName={'The-Database-Year-5-corrected'}
          currDataFileName={currDataFileName}
        />
        <HXMainMenuButton
          title={'Grade 6'}
          dataFileName={'The-Database-Year-6-corrected'}
          currDataFileName={currDataFileName}
          extraStyle={{borderRadius:'0px 40px 0px 0px',borderWidth:'1px'}}
        />
      </div>
      <div name="bot-row" className="-mp-lay-fl"
        style={{width:'100%'}}
      >
        <HXMainMenuHomeButton
          showHomePage={showHomePage}
          extraStyle={{borderRadius:'0px 0px 0px 40px', borderWidth:'0px 0px 1px 1px'}}
        />
        <HXMainMenuButton
          title={'Grades 1-2'}
          dataFileName={'Cumulative-Year-1-to-2'}
          currDataFileName={currDataFileName}
          extraStyle={{borderWidth:'0px 0px 1px 1px'}}
        />
        <HXMainMenuButton
          title={'Grades 1-3'}
          dataFileName={'Cumulative-Years-1-to-3'}
          currDataFileName={currDataFileName}
          extraStyle={{borderWidth:'0px 0px 1px 1px'}}
        />
        <HXMainMenuButton
          title={'Grades 1-4'}
          dataFileName={'Cumulative-Years-1-to-4'}
          currDataFileName={currDataFileName}
          extraStyle={{borderWidth:'0px 0px 1px 1px'}}
        />
        <HXMainMenuButton
          title={'Grades 1-5'}
          dataFileName={'Cumulative-Years-1-to-5'}
          currDataFileName={currDataFileName}
          extraStyle={{borderWidth:'0px 0px 1px 1px'}}
        />
        <HXMainMenuButton
          title={'Grades 1-6'}
          dataFileName={'Cumulative-Years-1-to-6'}
          currDataFileName={currDataFileName}
          extraStyle={{borderRadius:'0px 0px 40px 0px',borderWidth:'0px 1px 1px 1px'}}
        />
        {
          showLemmas &&
          <HXMainMenuButton
            title={'Lemmas'}
            dataFileName={'Lemmas'}
            currDataFileName={currDataFileName}
            extraStyle={{borderColor:'#888888',borderRadius:'40px',borderStyle:'solid',borderWidth:'1px',boxSizing:'border-box',float:'right',height:'40px',position:'absolute',right:'-53px',top:'44px',width:'80px'}}
          />
        }
      </div>
    </div>
  )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const HXMainMenuButton = (props) => {
  const dispatch = useDispatch();
  const handleClick = (dataFileName) => {
    dispatch({type: 'dataFileName', payload: dataFileName});
    dispatch({type: 'showHomePage', payload: false});
  }

  let className = "menu-btn -mp-lay-fl";
  if(props.currDataFileName === props.dataFileName) {
    className += ' SELECTED';
  }
  return (
    <a
      onClick={() => handleClick(props.dataFileName)}
      style={props.extraStyle}
      className={className}
    >
      <div name="label" className="menu-btn-label">
        {props.title}
      </div>
    </a>
  )
}

HXMainMenuButton.propTypes = {
  title: PropTypes.string,
  dataFileName: PropTypes.string,
  currDataFileName: PropTypes.string,
  extraStyle: PropTypes.object,
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const HXMainMenuHomeButton = (props) => {
  const dispatch = useDispatch();
  const handleClick = (dataFileName) => {
    dispatch({type: 'showHomePage', payload: true});
  }

  let className = "menu-btn -mp-lay-fl";
  if(props.showHomePage) {
    className += ' SELECTED';
  }
  return (
    <a
      onClick={() => handleClick()}
      style={props.extraStyle}
      className={className}
    >
      <div name="label" className="menu-btn-label"
        style={{fontSize:'20px', fontWeight:'bold', color:'#666666'}}
      >
        Home
      </div>
    </a>
  )
}

HXMainMenuHomeButton.propTypes = {
  showHomePage: PropTypes.bool,
  extraStyle: PropTypes.object,
};


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const HXMainMenuLemmasButton = (props) => {

  return (
    <a
      name="lemmas-link"
      className="menu-btn -mp-lay-fl"
      style={{backgroundColor:'#ffffff',borderColor:'#888888',borderRadius:'40px',borderStyle:'solid',borderWidth:'1px',boxSizing:'border-box',float:'right',height:'40px',position:'absolute',right:'-53px',top:'48px',width:'80px'}}
      href="/grades1-6-lemmas"
    >
      <div name="label" className="menu-btn-label">
        Lemmas
      </div>
    </a>
  )
}